import PropTypes from 'prop-types';
import React from 'react';

function RxBundleButtons({ allOptionsSelected, setReactCartObjectChanged }) {

    const handleSendOrder = () => {
      setReactCartObjectChanged()
      openSendBundleModal()
    };

    const handleSaveBundle = () => {
      setReactCartObjectChanged()
      openSaveBundleModal()
    };

    const handlePrintOrder = () => {
      setReactCartObjectChanged()
      openPrintableBundleModal()
    };

    const handleFrontOffice = () => {
      setReactCartObjectChanged()
      openFrontOfficeModal()
    };

    return (
      <div className="rx-bundle-buttons-cont">
          <button
            onClick={handleSendOrder}
            className={`rx-bundle-button ${!allOptionsSelected ? 'disabled tooltip' : ''}`}
            type="button"
            disabled={!allOptionsSelected}
            data-tooltip={!allOptionsSelected ? 'Please confirm all of the product options to continue' : ''}
          >
              Send as RxCart&trade; Order
          </button>
          <button
            onClick={handleSaveBundle}
            className="rx-bundle-button"
            type="button"
          >
              Save RxCart&trade; Bundle
          </button>
          <button
            onClick={handlePrintOrder}
            className={`rx-bundle-button ${!allOptionsSelected ? 'disabled tooltip' : ''}`}
            type="button"
            disabled={!allOptionsSelected}
            data-tooltip={!allOptionsSelected ? 'Please confirm all of the product options to continue' : ''}
          >
              Printable RxCart&trade; Order
          </button>
          <button
            onClick={handleFrontOffice}
            className={`rx-bundle-button ${!allOptionsSelected ? 'disabled tooltip' : ''}`}
            type="button"
            disabled={!allOptionsSelected}
            data-tooltip={!allOptionsSelected ? 'Please confirm all of the product options to continue' : ''}
            title={allOptionsSelected ? 'Use 4 digit code generated from &quot;Front Office Clerk&quot; button at check out or under &quot;View Bundles&quot; tab for on-site order processing' : ''}
          >
              Front Office Clerk
          </button>
      </div>
    )
}

export default RxBundleButtons;