import PropTypes from 'prop-types';
import React, {useState} from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    zIndex: '99999',
    transform: 'translate(-50%, -50%)',
    width: '358px',
    textAlign: 'center',
    fontFamily: 'MyriadPro-Regular, arial, sans-serif',
  },
  overlay: {
    zIndex: 9999,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  }
};

function FOCOrders(props) {
  const [location, setLocation] = useState((props.default_location ? props.default_location : 'All'));
  const [carts, setCarts] = useState(props.foc_carts );
  const [visibleCarts, setVisibleCarts] = useState((props.default_location ? props.foc_carts.filter(cart => cart.ship_num === props.default_location) : props.foc_carts));
  const [selectedCart, setSelectedCart] = useState({});
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const handleChangeLocation = (event) => {
    setLocation(event.target.value);
    if (event.target.value === 'All') {
      setVisibleCarts(carts);
    } else {
      setVisibleCarts(carts.filter(cart => cart.ship_num === event.target.value));
    }
  }

  const handleDeleteCart = () => {
    fetch("/api/carts/" + selectedCart.id, {
      method: "DELETE"
    }).then(response => {
      if (response.status !== 200) {
        console.log('Something went wrong when deleting the order. Status Code: ' + response.status);
        response.json().then(data => {
          data.msg && console.log(data.msg);
        });
        return;
      }

      setCarts(carts.filter(cart => cart.id !== selectedCart.id))
      setVisibleCarts(visibleCarts.filter(cart => cart.id !== selectedCart.id))
    })
    closeModal();
  }

  const openModal = (cart) => {
    Modal.setAppElement(document.getElementById('ecwid_body'));
    setSelectedCart(cart);
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
    setSelectedCart({});
  }

  const filterBlock = <div className="foc-orders-filter">
    <label>Filter by Clinic Location</label>
    <div className="select-status">
      <select value={location} onChange={handleChangeLocation}>
        <option value="All">All</option>
        {props.locations.map((location, index) => (
            <option key={index} value={location.ship_num}>{location.cust_name} ({location.ship_num}) {location.address_1} {location.address_2} {location.city} {location.province}</option>
        ))}
      </select>
    </div>
  </div>;

  return (
    <div className="rpos-ready-to-checkout">
      <p>RxCart Ready for Check Out</p>
      {
        props.locations.length > 1
            ? filterBlock
            : null
      }

      <table className="carts_data">
        <thead>
        <tr>
          <th>Patient/Client Name</th>
          <th>Date</th>
          <th>Time</th>
          <th>Order Code</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {visibleCarts.map((cart) => (
            <tr key={cart.id}>
              <td>{cart.patient_name}</td>
              <td>{new Date(cart.created_at).toLocaleString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit' })}</td>
              <td>{new Date(cart.created_at).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}</td>
              <td>{cart.code}</td>
              <td className="cart-code-link">
                <a href={`/patient_cart?rxcart=code_${cart.code}`} target="_blank">Open Order</a>
                <button className="action-button" onClick={() => openModal(cart)}>Delete</button>
              </td>
            </tr>
        ))}
        </tbody>
      </table>

      <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Delete Cart Modal"
      >
        <p>Are you sure?</p>
        <button className='action-button modal-button' onClick={handleDeleteCart}>OK</button>
        <button className='action-button modal-button modal-cancel-button' onClick={closeModal}>Cancel</button>
      </Modal>
    </div>
  );
}

FOCOrders.propTypes = {
  foc_carts: PropTypes.array.isRequired,
  locations: PropTypes.array,
  default_location: PropTypes.string,
  authenticity_token: PropTypes.string
};

export default FOCOrders;
