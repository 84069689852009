import React, { useEffect, useState } from 'react';
import RxBundleItem from './RxBundleItem';
import RxBundleButtons from './RxBundleButtons';
import './rxbundle.scss';

function RxBundle() {
  const productsInfo = window.reactCartObject?.productsInfo || {};
  const [currentCartItems, setCurrentCartItems] = useState(window.reactCartObject?.ecwidCart?.items || []);
  const [reactCartObjectChanged, setReactCartObjectChanged] = useState(false);
  const [allOptionsSelected, setAllOptionsSelected] = useState(false);
  const [currentCartSelectedOptions, setCurrentCartSelectedOptions] = useState({});

  useEffect(() => {
    // Check if all product options are selected
    if (Object.keys(currentCartSelectedOptions).length === 0) {
      setAllOptionsSelected(false);
      return;
    }

    const allSelected = Object.values(currentCartSelectedOptions).every(item =>
      Object.values(item).every(value => value !== '')
    );

    setAllOptionsSelected(allSelected);

  }, [currentCartItems, currentCartSelectedOptions]);

  useEffect(() => {
    if (reactCartObjectChanged) {
      window.updateEcwidCart(currentCartItems);
      setReactCartObjectChanged(false);
    }
  }, [reactCartObjectChanged, currentCartItems]);

  useEffect(() => {
    const handleReactCartObjectChange = () => {
      if(window.reactCartObject && window.reactCartObject.ecwidCart) {
        setCurrentCartItems(window.reactCartObject.ecwidCart.items);
      }
    };
    window.addEventListener('reactCartObjectChange', handleReactCartObjectChange);
    return () => {
      window.removeEventListener('reactCartObjectChange', handleReactCartObjectChange);
    };
  }, []);

  useEffect(() => {
    console.log('CurrentCartSelectedOptions', currentCartSelectedOptions)
  }, [currentCartSelectedOptions]);

  useEffect(() => {
    const productsOptions = {}
    currentCartItems.forEach((item) => {
      if (item.product) {
        productsOptions[item.product.id] = {};
        if (item.options) {
          if (item.product.id in currentCartSelectedOptions) {
            productsOptions[item.product.id] = currentCartSelectedOptions[item.product.id]
          } else {
            for (let optionName in item.options) {
              productsOptions[item.product.id][optionName] = "";
            }
          }
        }
      }
    });
    setCurrentCartSelectedOptions(productsOptions);
  }, [currentCartItems]);

  const setReactCartObjectChangedTrue = () => setReactCartObjectChanged(true);

  const handleRemoveProduct = (index, productId) => {
    setCurrentCartItems(currentCartItems.filter((item, i) => i !== index));
    setCurrentCartSelectedOptions(previousSelectedOptions => {
      const newSelectedOptions = { ...previousSelectedOptions };
      delete newSelectedOptions[productId];
      return newSelectedOptions;
    })
    window.removeProductFromEcwidCart(index);
  };

  const handleProductQtyChange = (productId, index, quantity) => {
    setCurrentCartItems(currentCartItems.map((item, i) => (item.product.id === productId && i === index) ? {...item, quantity: Number(quantity)} : item));
    setReactCartObjectChanged(true);
  };

  const handleProductOptionsChange = (productId, index, selectedOptions) => {
    setCurrentCartItems(currentCartItems.map((item, i) => (item.product.id === productId && i === index) ? {...item, options: selectedOptions} : item));
    setCurrentCartSelectedOptions(prevOptions => ({ ...prevOptions, [productId]: selectedOptions }));
  };

  const handleContinueSearching = () => {
    openEcwidCategoryPage(0)
  };

  return (
    <div>
      <h1 className="rx-bundle-header">Your RxBundle</h1>
      <div className="rx-bundle-container">
        <div className="rx-bundle-content">
          {currentCartItems.length > 0 ? (
            <>
              <div className="confirm-message-cont">
                {!allOptionsSelected && <span>Please confirm or change the highlighted options below.</span>}
              </div>
              {currentCartItems.map((item, index) => {
                const productInfo = productsInfo[item.product.id];

                if (!productInfo) {
                  console.warn(
                    `Product information missing for product ID: ${item.product.id}`
                  );
                  return (
                    <div
                      key={`${item.product.id}_${index}`}
                      className="missing-product"
                    >
                    </div>
                  );
                }

                return (
                  <RxBundleItem
                    key={`${item.product.id}_${index}`}
                    item={item}
                    index={index}
                    img={productsInfo[item.product.id]['hdThumbnailUrl']}
                    availableOptions={productsInfo[item.product.id]['options']}
                    selectedOptions={currentCartSelectedOptions[item.product.id]}
                    onDelete={() => handleRemoveProduct(index, item.product.id)}
                    onQtyChange={handleProductQtyChange}
                    onOptionsChange={handleProductOptionsChange}
                  />
                );
              })}
            </>
          ) : (
            <div>
              <p>Your RxBundle is empty.</p>
            </div>
          )}
        </div>
        {currentCartItems.length > 0 && <RxBundleButtons allOptionsSelected={allOptionsSelected} setReactCartObjectChanged={setReactCartObjectChangedTrue} />}
      </div>
      <div className="continue-searching-button-cont">
        <button className="continue-searching-button" type="button" onClick={handleContinueSearching}>Continue Searching</button>
      </div>
    </div>
  );
}

export default RxBundle;