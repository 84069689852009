//import useState hook to create menu collapse state
import React, { useState } from "react";
import PropTypes from 'prop-types';

//import react pro sidebar components
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  SubMenu
} from "react-pro-sidebar";


//import icons from react icons
import { FaList, FaRegHeart, FaMarker, FaLayerGroup, FaBars } from "react-icons/fa";
import { FiHome, FiLogOut, FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";
import { RiPencilLine, RiDashboardLine } from "react-icons/ri";
import { BiCog } from "react-icons/bi";
import frontOfficeClerkIcon from './front-office-clerk-icon.png'


//import sidebar css from react-pro-sidebar module and our custom css
import "./sidebar.scss";
import "react-pro-sidebar/dist/css/styles.css";

const RXMenu = (props) => {

  const menuCollapsed = localStorage.getItem('menu-collapsed');

  //create initial menuCollapse state using useState hook
  const [menuCollapse, setMenuCollapse] = useState(menuCollapsed ? false : true);

  //create a custom function that will change menucollapse state from false to true and true to false
  const menuIconClick = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      localStorage.setItem('menu-collapsed', true);
      return;
    }
    setMenuCollapse(true);
    localStorage.removeItem('menu-collapsed')
  };

  const reportMenuItem = props.request.controller === 'reports' ? true : false,
    newRPOMenuItem = props.request.controller === 'rpo' && props.request.action === 'new' ? true : false,
    newRxBundleMenuItem = props.request.controller === 'carts' && props.request.action === 'new' ? true : false,
    viewRxBundlesMenuItem = props.request.controller === 'carts' && !props.request.printable && !props.request.all && props.request.action != 'new' ? true : false,
    viewRxCartPendingItem = props.request.controller === 'carts' && props.request.all && props.request.orders_status === 'pending' ? true : false,
    viewRxCartCompletedItem = props.request.controller === 'carts' && props.request.all && props.request.orders_status === 'completed' ? true : false,
    administrationMenuItem = props.request.controller === 'administration' ? true : false,
    frontOfficeClerkItem = props.request.controller === 'foc' ? true : false;
  const [toggled, setToggled] = useState(false);
  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <div>
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <ProSidebar
          toggled={toggled}
          breakPoint={"md"}
          onToggle={handleToggleSidebar}
      >
        <SidebarHeader>
          <div className="logotext">
            {/* small and big change using menucollapse state */}
            {/*<p>{menuCollapse ? "Logo" : "Big Logo"}</p>*/}
            <div className={"sidebar-logo"}></div>
          </div>
          <div className="closemenu" onClick={menuIconClick}>
            {/* changing menu collapse icon on click */}
            {menuCollapse ? (
              <FiArrowRightCircle />
            ) : (
              <FiArrowLeftCircle />
            )}
          </div>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="square">
            <SubMenu open={true} title="RxCart&trade; Orders" icon={<RiPencilLine />}>
              <MenuItem active={newRPOMenuItem}><a href="/rpo/new">Create New RxCart&trade; Order</a></MenuItem>
              <MenuItem active={viewRxCartPendingItem}><a href="/carts?all=true&orders_status=pending">Pending RxCart&trade; Orders</a></MenuItem>
              <MenuItem active={viewRxCartCompletedItem}><a href="/carts?all=true&orders_status=completed">Completed RxCart&trade; Orders</a></MenuItem>
            </SubMenu>

            <SubMenu open={true} title="RxCart&trade; Bundles" className="rx-bundle" icon={<FaLayerGroup />}>
              <MenuItem active={newRxBundleMenuItem}><a href="/carts/new">Create New RxCart&trade; Bundle</a></MenuItem>
              <MenuItem active={viewRxBundlesMenuItem}><a href="/carts">View RxCart&trade; Bundles</a></MenuItem>
            </SubMenu>
            {(props.is_admin === true || props.is_subadmin === true) &&
            <MenuItem active={administrationMenuItem} icon={<RiDashboardLine />}> <a href="/administration">RxCart&trade; Administration</a> </MenuItem>
            }
            <MenuItem active={reportMenuItem} icon={<RiDashboardLine />}> <a href="/reports">Reports</a> </MenuItem>
            <MenuItem icon={<FiHome />}><a href={props.main_url}> Back to Main Menu</a></MenuItem>
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <Menu iconShape="square">
            <MenuItem className="front-office-clerk" active={frontOfficeClerkItem} icon={<img src={frontOfficeClerkIcon} width={'22px'} height={'25px'} />}> <a href="/foc">Front Office Clerk</a></MenuItem>
          </Menu>
          <Menu iconShape="square">
            <MenuItem icon={<FiLogOut />}> <a href="/login/destroy">Logout</a></MenuItem>
          </Menu>
        </SidebarFooter>
      </ProSidebar>
    </div>
  );
};

export default RXMenu;