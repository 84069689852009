import PropTypes from 'prop-types';
import React, {useState} from 'react';
import RxBundleProductQty from './RxBundleProductQty'

function RemoveIcon() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" stroke="currentColor">
        <path d="M.5.5l11 11M.5 11.5l11-11"></path>
      </g>
    </svg>
  );
}

function RxBundleItem({ item, index, img, availableOptions, selectedOptions, onDelete, onQtyChange, onOptionsChange }) {

  const { id, name, price, url } = item.product;
  const [qtyValue, setQtyValue] = useState(item.quantity);

  const handleQtyChange = (qty) => {
    setQtyValue(qty);
    onQtyChange(id, index, qty);
  };

  const handleOptionChange = (optionName, e) => {
    const newSelectedOptions = { ...selectedOptions, [optionName]: e.target.value };
    onOptionsChange(id, index, newSelectedOptions);
  };

  const handleProductClick = (e) => {
    e.preventDefault();
    openEcwidProductPage(id)
  };

  return (
    <div className="rx-bundle-product">
      <div className="rx-bundle-product-img">
        <a className="rx-bundle-product-img-inner rx-bundle-product-link" href="#" onClick={handleProductClick}>
          <img src={img} alt={name} />
        </a>
      </div>
      <div className="rx-bundle-product-wrap">
        <div className="rx-bundle-product-wrap-primary">
          <div className="rx-bundle-product-wrap-top">
            <a className="rx-bundle-product-title rx-bundle-product-link" href='#' onClick={handleProductClick}>{name}</a>
            <div className="rx-bundle-product-control">
              <a
                role="button"
                tabIndex="0"
                aria-label="Remove Item"
                className="rx-bundle-product-control-inner"
                onClick={onDelete}
              >
                <RemoveIcon />
              </a>
            </div>
          </div>
          <div className="rx-bundle-product-options">
            {availableOptions.length > 0 && selectedOptions && availableOptions.map((option) => (
              <div key={`${id}_${option.name}`} className="rx-bundle-product-option">
                <span>{option.name}: </span>
                <select
                  value={selectedOptions[option.name] || ""}
                  onChange={(e) => handleOptionChange(option.name, e)}
                  style={{ borderColor: selectedOptions[option.name] === '' ? 'red' : '' }}
                >
                  <option value="">Select...</option>
                  {option.choices.map((choice, choiceIndex) => (
                    <option key={choiceIndex} value={choice.text}>
                      {choice.text}
                    </option>
                  ))}
                </select>
              </div>
            ))}
          </div>
        </div>
        <div className="rx-bundle-product-wrap-secondary">
          <div className="rx-bundle-product-count">
            <div className="rx-bundle-product-count-text">Qty: </div>
            <RxBundleProductQty value={qtyValue} setQtyValue={handleQtyChange}/>
          </div>
          <div className="rx-bundle-product-price">{(price*qtyValue).toFixed(2)}</div>
        </div>
      </div>
    </div>
  );
}

RxBundleItem.propTypes = {
  item: PropTypes.shape({
    product: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired
    }).isRequired,
    quantity: PropTypes.number.isRequired
  }).isRequired,
  img: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onQtyChange: PropTypes.func.isRequired,
  availableOptions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      defaultChoice: PropTypes.number.isRequired,
      choices: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired
        })
      ).isRequired
    })
  ).isRequired,
};

export default RxBundleItem;