import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';

function RxBundleProductQty({ value, setQtyValue}) {

  const [qtyInputValue, setQtyInputValue] = useState(value);

  useEffect(() => {
    setQtyInputValue(value);
  }, [value]);

  const handleQtyInputChange = (qty) => {
    setQtyInputValue(qty.replace(/[^0-9]+/g, ""));
  }

  const handleQtyInputBlur = () => {
    setQtyValue(qtyInputValue);
  }

  const handleQtyInputKeyDown = (e) => {
    if (e.key === "Enter" || e.key === "Escape") {
      e.currentTarget.blur();
    }
  }

  const handleQtySelectChange = (qty) => {
    setQtyValue(qty);
  }

  const handleFocus = (e) => {
    e.target.select();
  }

  return (
    value < 10
      ?
      <select
        className="rx-bundle-product-count-select"
        value={value}
        aria-label="Quantity:"
        onChange={e => handleQtySelectChange(e.target.value)}
      >
        {Array.from({length: 10}, (_, i) => i + 1).map(num =>
          <option key={num} value={num}>{num}</option>
        )}
      </select>
      :
      <input
        type="text"
        value={qtyInputValue}
        onChange={e => handleQtyInputChange(e.target.value)}
        onFocus={handleFocus}
        onKeyDown={e => handleQtyInputKeyDown(e)}
        onBlur={handleQtyInputBlur}
        maxLength="4"
        autoComplete="off"
      />
  )
}

RxBundleProductQty.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  setQtyValue: PropTypes.func.isRequired,
};

export default RxBundleProductQty;