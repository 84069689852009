import ReactOnRails from 'react-on-rails';

import HelloWorld from '../bundles/HelloWorld/components/HelloWorld';
import FOCOrders from '../bundles/FOCOrders/components/FOCOrders';
import RXMenu from '../bundles/RXMenu/components/RXMenu';
import RxBundle from '../bundles/RxBundle/components/RxBundle';
import PatientCartConfirmOptions from '../bundles/RxBundle/components/PatientCartConfirmOptions';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  HelloWorld,
  RXMenu,
  FOCOrders,
  RxBundle,
  PatientCartConfirmOptions
});
